<template>
	<v-main class="new-password">
		<v-container fluid fill-height>
			<v-layout align-center justify-center>
				<v-flex xs12 sm8 md4>
					<v-card class="elevation-12">
						<v-toolbar dark color="primary">
							<v-toolbar-title>
								Password Reset Required
							</v-toolbar-title>
							<v-spacer></v-spacer>
						</v-toolbar>
						<v-card-text>
							<v-form ref="form">
								<v-text-field
									v-model="password"
									name="password"
									label="Password"
									type="password"
									autocomplete="new-password"
									:rules="rules.password"
									@submit.prevent="resetPassword"
									@keypress.enter.prevent="resetPassword"
								></v-text-field>
								<v-text-field
									v-model="confirmPassword"
									name="confirm-password"
									label="Confirm Password"
									type="password"
									autocomplete="new-password"
									:rules="[v => v === this.password || 'Passwords do not match']"
									@submit.prevent="resetPassword"
									@keypress.enter.prevent="resetPassword"
								></v-text-field>
								<p v-if="error" class="red--text">{{ error }}</p>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" v-on:click="resetPassword" :loading="loading">Submit</v-btn>
						</v-card-actions>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</v-main>
</template>

<script>
import { confirmSignIn, fetchAuthSession } from 'aws-amplify/auth';
import cookieManager from 'mdfit-frontend-common/api/cookieManager';
import config from 'mdfit-frontend-common/api/config';

const containsNumber = /^.*[0-9]+.*$/;

export default {
	data: () => ({
		password: '',
		confirmPassword: '',
		rules: {
			password: [
				v => !!v || 'Password is required',
				v => v.length >= 8 || 'Password must be at least 8 characters',
				v => v.toLowerCase() !== v || 'Password must contain at least one uppercase character',
				v => containsNumber.test(v) || 'Password must contain at least one number',
			],
		},
		newPasswordMode: false,
		loading: false,
		error: null,
	}),
	computed: {
		cognitoUser() {
			return this.$store.state.user.cognitoUser;
		},
		tenantSettings() {
			return this.$store.state.tenantSettings;
		},
	},
	methods: {
		resetPassword() {
			const valid = this.$refs.form.validate();
			if (valid) {
				this.error = null;
				this.loading = true;
				confirmSignIn({
					challengeResponse: this.password,
				}).then((user) => {
					if (user.isSignedIn) {
						fetchAuthSession().then((session) => {
							cookieManager.setCookie('expires_at', Date.now() +
								(session.tokens.accessToken.payload.exp * 1000));
							this.loading = false;

							const storedRedirect = cookieManager.getCookie('loginRedirect');
							if (storedRedirect) {
								cookieManager.deleteCookie('loginRedirect');
								window.location.href = decodeURIComponent(storedRedirect);
							} else {
								window.location.href = this.tenantSettings.homePage || config.homePage;
							}
						});
					} else if (user.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE'
						|| user.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE'
					) {
						this.$store.commit('user/setCognitoUser', user);
						this.$router.push({ path: '/mfaToken', name: 'mfaToken' });
					} else if (user.nextStep?.signInStep === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP') {
						// MFA is required, but user is not setup.
						this.$store.commit('user/setCognitoUser', user);
						this.$router.push({ path: '/setupMfa', name: 'setupMFA' });
					} else {
						const storedRedirect = cookieManager.getCookie('loginRedirect');
						if (storedRedirect) {
							cookieManager.deleteCookie('loginRedirect');
							window.location.href = decodeURIComponent(storedRedirect);
						} else {
							window.location.href = this.tenantSettings.homePage || config.homePage;
						}
					}
					this.loading = false;
				}).catch((error) => {
					console.error(error);
					this.loading = false;
					this.error = error.message;
				});
			}
		},
	},
};
</script>
