<template>
	<v-container class="idpresponse">
		<v-row>
			<v-card
				v-if="accessDenied"
				width="600"
			>
				<v-toolbar dark color="primary">
					<v-toolbar-title>Access Denied</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<div class="mb-2">
						Access was denied, please contact your IT group.
					</div>
					<v-btn :to="'org/' + tenant" color="primary">Back to Login</v-btn>
				</v-card-text>
			</v-card>
			<v-card
				v-if="loginError"
				width="600"
			>
				<v-toolbar dark color="primary">
					<v-toolbar-title>Login Error</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<div class="mb-2">
						There was an error with single sign on, please contact your IT group.
					</div>
					<div class="red--text mb-1">
						{{ loginError[0] }}
					</div>
					<div class="red--text mb-2">
						{{ loginError[1] }}
					</div>
					<v-btn :to="'org/' + tenant" color="primary">Back to Login</v-btn>
				</v-card-text>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import config from 'mdfit-frontend-common/api/config';
import cookieManager from 'mdfit-frontend-common/api/cookieManager';

export default {
	data: () => ({
		accessDenied: false,
		loginError: false,
	}),
	computed: {
		tenant() {
			return cookieManager.getCookie('tenant');
		},
	},
	mounted() {
		const query = window.location.href.split('?')[1];
		const params = query.split('&').map(q => q.split('='));
		const codeParam = params.find(p => p[0] === 'code');
		if (!codeParam) {
			// There must have been an error
			const errorName = params.find(p => p[0] === 'error');
			const errorParam = params.find(p => p[0] === 'error_description');
			const error = decodeURIComponent(errorParam[1]);
			console.error(errorName[1]);
			console.error(error);
			if (errorName[1] === 'access_denied') {
				this.accessDenied = true;
			} else {
				this.loginError = [errorName[1], error];
			}
		} else if (window.opener ||
			(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.cordova_iab)) {
			// login window was opened in another window, send message back to let
			// originating window know it is done
			const urlParams = new URLSearchParams(window.location.search);
			const opener = window.opener || window.webkit.messageHandlers.cordova_iab;
			opener.postMessage(JSON.stringify({
				type: 'LOGIN_CODE_COMPLETE',
				code: urlParams.get('code'),
			}), '*');
			// Wait a few seconds and redirect in case this is a web login with an opener set
			setTimeout(() => {
				this.federatedLogin();
			}, 2000);
		} else {
			this.federatedLogin();
		}
	},
	methods: {
		federatedLogin() {
			this.$store.dispatch('user/federatedLogin')
				.then(({ tenant: tenantInfo }) => {
					const tenant = cookieManager.getCookie('tenant');
					const storedRedirect = cookieManager.getCookie('loginRedirect');
					if (!tenant) {
						console.log('redirect to account select');
						cookieManager.deleteCookie('loginRedirect');
						this.$router.push('accountselect');
					} else if (storedRedirect) {
						cookieManager.deleteCookie('loginRedirect');
						window.location.href = decodeURIComponent(storedRedirect);
					} else {
						window.location.href = tenantInfo.homePage || config.homePage;
					}
				});
		},
	},
};
</script>

<style scoped>
.idpresponse {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.idpresponse .row {
	justify-content: center;
	flex: 0 0 auto;
}
</style>
