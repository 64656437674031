/**
 * This vuex store contains information about the currently logged in user
 */
import { signIn, signOut, fetchAuthSession, fetchMFAPreference } from 'aws-amplify/auth';
import cookieManager from 'mdfit-frontend-common/api/cookieManager';
import config from 'mdfit-frontend-common/api/config';
import amplifyConfig from 'mdfit-frontend-common/api/amplify-config';
import * as ADFS from './ADFSLogin';
import router from '../../router';

export default () => ({
	namespaced: true,
	state: {
		username: '',
		loggedIn: false,
		accessToken: '',
		changingPassword: false,
		userData: null,
		cognitoUser: null,
	},
	mutations: {
		logIn(state, { username, accessToken }) {
			Object.assign(state, {
				loggedIn: true,
				username,
				accessToken,
			});
		},
		logOut(state) {
			Object.assign(state, {
				username: '',
				loggedIn: false,
				avatar: '',
				userData: null,
			});
		},
		changePasswordInit(state, { username, userData }) {
			Object.assign(state, {
				username,
				loggedIn: false,
				changingPassword: true,
				userData,
			});
		},
		setCognitoUser(state, user) {
			state.cognitoUser = user;
		},
		setChallengeName(state, name) {
			state.cognitoUser.challengeName = name;
		},
	},
	actions: {
		initiateFederatedLogin(_, tenantInfo) {
			// Delete any old access tokens first
			cookieManager.deleteCookie('access_token');
			cookieManager.deleteCookie('refresh_token');
			cookieManager.deleteCookie('expires_at');
			ADFS.userLogin(tenantInfo, `${config.loginUrl}/idpresponse`);
		},
		federatedLogin() {
			const apis = amplifyConfig.API.REST[config.api];
			return fetch(`${apis.endpoint}/permissions/tenants/${cookieManager.getCookie('tenant')}`)
				.then(response => response.json())
				.then(tenant =>
					ADFS.getTokens(tenant).then((token) => {
						cookieManager.setCookie('access_token', token.access_token);
						cookieManager.setCookie('refresh_token', token.refresh_token);
						cookieManager.setCookie('expires_at', Date.now() + (token.expires_in * 1000));
						return { tenant, token };
					}));
		},
		login({ commit, rootState }, { username, password, homePage }) {
			// Delete any old access tokens first
			cookieManager.deleteCookie('access_token');
			cookieManager.deleteCookie('refresh_token');
			cookieManager.deleteCookie('expires_at');
			return signOut().then(() => signIn({
				username: username.toLowerCase(),
				password,
			})).then((user) => {
				if (user.isSignedIn) {
					fetchAuthSession().then(async (session) => {
						cookieManager.setCookie('expires_at', (session.tokens.accessToken.payload.exp * 1000));

						// If tenant has mfa required, and user has no mfa, go to mfa setup now
						const mfaRequired = rootState.tenantSettings.mfaRequired;
						if (mfaRequired) {
							const mfa = await fetchMFAPreference();
							if (!mfa.enabled || mfa.enabled.length === 0) {
								commit('setCognitoUser', user);
								router.push({ path: '/setupMfa', name: 'setupMFA', params: { username } });
								return;
							}
						}

						const query = window.location.href.split('?')[1] || '';
						const params = query.split('&').map(q => q.split('='));
						const loginRedirect = params.find(p => p[0] === 'redirect');
						if (loginRedirect) {
							const [, url] = loginRedirect;
							window.location.href = decodeURIComponent(url);
						} else {
							const storedRedirect = cookieManager.getCookie('loginRedirect');
							if (storedRedirect) {
								cookieManager.deleteCookie('loginRedirect');
								window.location.href = decodeURIComponent(storedRedirect);
							} else {
								window.location.href = homePage || config.homePage;
							}
						}
					});
				} else if (user.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
					commit('setCognitoUser', user);
					router.push('/newpassword');
				} else if (user.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE'
					|| user.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE'
				) {
					commit('setCognitoUser', user);
					router.push({ path: '/mfaToken', name: 'mfaToken' });
				} else if (user.nextStep?.signInStep === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP') {
					// MFA is required, but user is not setup.
					commit('setCognitoUser', user);
					router.push({ path: '/setupMfa', name: 'setupMFA', params: { username } });
				} else if (user.nextStep?.signInStep === 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION') {
					// MFA is required, but user is not setup.
					commit('setCognitoUser', user);
					router.push({ path: '/selectMFA', name: 'selectMFA', params: { username } });
				}
				return user;
			});
		},
	},
});
