<template>
	<v-main class="login-page">
		<v-container fluid fill-height :class="{ 'pa-0': mobile }">
			<v-layout align-center justify-center column>
				<v-card
					class="login-card mb-1"
					:elevation="tenantInfo.ssoName ? 6 : 2"
					style="max-width: 400px;z-index:20;"
					v-if="!$route.params.tenant || tenantLoaded"
				>
					<v-card-title dark color="primary" class="ma-0 pa-0" style="display: block;">
						<img
							v-if="tenantInfo.organizationImage"
							:src="tenantInfo.organizationImage"
						/>
						<img v-else
							:src="defaultImage"
						/>
					</v-card-title>
					<v-card-text>
						<div v-html="tenantInfo.signOnGreeting" class="text-center rich-text mb-4"></div>
						<v-row v-show="tenantInfo.ssoName">
							<v-col cols="12">
								<v-layout align-center justify-center
									:class="{
										'sso-button': true,
									}"
								>
									<v-btn block color="primary" :disabled="!tenantInfo.ssoName" @click="ssoLogin">
										Single Sign On
										<v-icon>mdi-call-made</v-icon>
									</v-btn>
								</v-layout>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<v-card style="width:400px;" class="pt-0">
					<v-card-text class="pt-0">
						<v-row class="my-0">
							<v-col cols="12" v-if="showUserPasswordLogin" class="pt-0">
								<v-row class="my-0">
									<v-col cols="5" v-show="tenantInfo.ssoName">
										<v-divider class="mt-3"></v-divider>
									</v-col>
									<v-col cols="2" v-show="tenantInfo.ssoName" class="text-center">
										<span class="overline">or</span>
									</v-col>
									<v-col cols="5" v-show="tenantInfo.ssoName">
										<v-divider class="mt-3"></v-divider>
									</v-col>
									<v-col cols="12">
										<span>Login with your MDfit account</span>
									</v-col>
								</v-row>
								<v-form ref="form">
									<v-text-field
										v-if="!$route.params.tenant"
										v-model="tenant"
										:rules="rules.tenant"
										label="Organization"
										name="tenant"
										type="text"
										outlined
										@submit.prevent="login"
										@keypress.enter.prevent="login"
										:error-messages="tenantErrorMessages"
									></v-text-field>
									<v-text-field
										v-model="username"
										@input="setUsername"
										:rules="rules.username"
										name="username"
										label="Username"
										type="text"
										outlined
										autocomplete="username email"
										@submit.prevent="login"
										@keypress.enter.prevent="login"
									></v-text-field>
									<div>
										<div style="width:100%;display:flex;justify-content:flex-end;">
										<v-checkbox
											hide-details
											label="Show Password"
											v-show="password.length"
											dense
											v-model="passwordRevealed"
											class="mt-0"
										/>
										</div>
									<v-text-field
										v-model="password"
										:rules="rules.password"
										name="password"
										label="Password"
										:type="passwordRevealed ? 'text' : 'password'"
										outlined
										autocomplete="current-password"
										@submit.prevent="login"
										@keypress.enter.prevent="login"
									></v-text-field>
									<v-row class="mx-1 mb-2 mt-0">
										<v-btn
											:outlined="!!tenantInfo.ssoName"
											block
											color="primary"
											@click="login"
											:loading="loading"
										>Login</v-btn>
										<span class="red--text mx-2" style="flex: 1 1;" v-if="error">{{ error }}</span>
									</v-row>
									</div>
								</v-form>
							</v-col>
						</v-row>
						<div class="rich-text mt-4"
							v-if="tenantInfo.signOnFooter && tenantInfo.signOnFooter.length"
							v-html="tenantInfo.signOnFooter"
						></div>
					</v-card-text>
				</v-card>
				<v-tooltip bottom v-if="tenantInfo.tenant">
					<template v-slot:activator="{ on }">
						<span v-on="on" class="mt-2">
							<router-link
								to="/forgotpassword?from=login"
								>Forgot Password?</router-link>
						</span>
					</template>
					<span>
						Send a password reset code to reset your password.
					</span>
				</v-tooltip>
			</v-layout>
		</v-container>
		<v-dialog v-model="emailResetDialog" width="800">
			<v-card>
				<v-card-title>
					<span>Password Reset Required</span>
					<v-spacer/>
					<v-btn icon @click="emailResetDialog = false;">
						<v-icon>close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="black--text">
					<div>
						<span>Your password must be reset, we have sent an email with a temporary password to</span>
						<span class="font-weight-bold"> {{ firstSignInInfo.emailAddress }}</span>
						<span>. After signing in with the temporary password, you must</span>
						<span> set a new password.</span>
					</div>
					<br />
					<div>If this email is incorrect, please contact support at {{ supportContact }}
						to correct it.</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="noEmailDialog" width="800">
			<v-card>
				<v-card-title>
					<span>Password Reset Required</span>
					<v-spacer/>
					<v-btn icon @click="noEmailDialog = false;">
						<v-icon>close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="black--text">
					Your password must be reset, but we do not have an email on file for you. Please contact support at
					<span class="font-weight-bold"> {{ supportContact }} </span>
					to add your email address to your account.
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-main>
</template>

<script>
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import debounce from 'lodash/debounce';
import cookieManager from 'mdfit-frontend-common/api/cookieManager';
import config from 'mdfit-frontend-common/api/config';
import CustomTokenStorage from '../store/modules/CustomTokenStorage.js';

export default {
	data: () => ({
		tenant: cookieManager.getCookie('tenant') || '',
		username: '',
		password: '',
		passwordRevealed: false,
		rules: {
			tenant: [
				v => !!v || 'Tenant is required',
			],
			username: [
				v => !!v || 'Username or Email is required',
				v => v.trim().indexOf(' ') === -1 || 'Username may not contain spaces',
			],
			password: [
				v => !!v.trim() || 'Please enter your password',
			],
		},
		lastApiTimestamp: 0,
		tenantInfo: {},
		newPasswordMode: false,
		loading: false,
		error: null,
		tenantLoaded: false,

		noEmailDialog: false,
		emailResetDialog: false,
		firstSignInInfo: {},
		supportContact: '',
		defaultImage: 'https://e60imageuploadsdev.s3.us-east-2.amazonaws.com/organizationImages/DEMO/logo_banner.png',
		tenantErrorMessages: [],
	}),
	computed: {
		apiMap() {
			return this.$store.state.apiMap;
		},
		mobile() {
			return this.$vuetify.breakpoint.mdAndDown;
		},
		showUserPasswordLogin() {
			return !this.tenantLoaded || !this.$route.params.tenant || this.tenantInfo.allowUserPassSignin;
		},
		showSingleSignon() {
			return !this.tenantLoaded || !this.$route.params.tenant || this.tenantInfo.allowSingleSignOn;
		},
	},
	watch: {
		tenant() {
			this.getTenantInfo();
		},
	},
	beforeRouteUpdate(route) {
		if (route.query.emailMissing) {
			this.noEmailDialog = true;
			this.supportContact = route.query.supportContact;
		} else if (route.query.sentTemporaryPassword) {
			this.emailResetDialog = true;
			this.supportContact = route.query.supportContact;
			this.firstSignInInfo = {
				emailAddress: route.query.sentTemporaryPassword,
			};
		}
	},
	created() {
		const pathTenant = this.$route.params.tenant;
		if (pathTenant) {
			this.tenant = pathTenant.toUpperCase();
			cookieManager.setCookie('tenant', this.tenant.toUpperCase());
		}
		this.getTenantInfo = debounce(this.getTenantInfo, 500);
		this.getTenantInfo();
		const route = this.$route;
		if (route.query.emailMissing) {
			this.noEmailDialog = true;
			this.supportContact = route.query.supportContact;
		} else if (route.query.sentTemporaryPassword) {
			this.emailResetDialog = true;
			this.supportContact = route.query.supportContact;
			this.firstSignInInfo = {
				emailAddress: route.query.sentTemporaryPassword,
			};
		}
	},
	methods: {
		setUsername(username) {
			this.$store.commit('setAttemptedUsername', username);
		},
		ssoLogin() {
			const query = window.location.href.split('?')[1];
			if (query) {
				const params = query.split('&').map(q => q.split('='));
				const loginRedirect = params.find(p => p[0] === 'redirect');
				if (loginRedirect) {
					const expires = new Date(Date.now() + (15 * 60 * 1000));
					cookieManager.setCookie('loginRedirect', loginRedirect[1], { expires: expires.toUTCString() });
				}
			}
			cookieManager.setCookie('tenant', this.tenant);
			this.$store.dispatch('user/initiateFederatedLogin', this.tenantInfo);
		},
		login() {
			if (this.tenantErrorMessages.length > 0) {
				return;
			}
			// Check if this is the first try at login
			const api = this.apiMap[config.api];
			this.loading = true;
			fetch(`${api}user/firstLogin/${this.username.trim()}`, {
				headers: { 'x-tenant': this.tenant },
			}).then(response => response.json())
				.then((signin) => {
					if (!signin.needsReset) {
						const valid = this.$refs.form.validate();
						if (!valid) {
							this.loading = false;
							return;
						}
						cookieManager.setCookie('tenant', this.tenant);
						this.error = null;
						this.$store.dispatch('user/login', {
							username: this.username.trim(),
							password: this.password.trim(),
							homePage: this.tenantInfo.homePage,
						}).catch((error) => {
							console.error(error);
							if (error.code === "NotAuthorizedException" || error.code === "UserNotFoundException") {
								this.error = "The email/username and/or password is invalid. Please try again.";
							} else {
								this.error = error.message;
							}
						}).finally(() => {
							this.loading = false;
						});
					} else if (signin.sentEmail) {
						// We just sent a reset email, let the user know
						this.firstSignInInfo = signin;
						this.supportContact = signin.supportContact;
						this.emailResetDialog = true;
						this.loading = false;
					} else {
						// No email on file so we have not sent an email. Notify user
						// They must contact support to add their email
						this.firstSignInInfo = signin;
						this.supportContact = signin.supportContact;
						this.noEmailDialog = true;
						this.loading = false;
					}
				}).catch((error) => {
					console.error(error);
					if (error.isAxiosError && error.code === 400) {
						this.error = error.message;
					}
					this.loading = false;
				});
		},
		getTenantInfo() {
			const lastApiTimestamp = Date.now();
			this.lastApiTimestamp = lastApiTimestamp;
			const api = this.apiMap[config.api];
			if (!api) {
				setTimeout(() => this.getTenantInfo(), 250);
				return;
			}
			fetch(`${api}/permissions/tenants/${this.tenant}`)
				.then(response => response.json())
				.then((tenant) => {
					if (lastApiTimestamp === this.lastApiTimestamp) {
						if (tenant.message === 'Not Found') {
							this.tenantInfo = {};
							this.tenantErrorMessages = ['Organization not found. Please check spelling.'];
							return;
						}
						if (tenant.message === 'Server Error') {
							this.tenantInfo = {};
							this.tenantErrorMessages = ['Unexpected Error retrieving organization details.'];
							return;
						}
						this.tenantErrorMessages = [];
						this.tenantInfo = tenant;
						const config = Amplify.getConfig();
						Amplify.configure({
							...config,
							Auth: {
								Cognito: {
									region: this.tenantInfo.cognitoRegion,
									userPoolId: this.tenantInfo.userPoolId,
									userPoolClientId: this.tenantInfo.userPoolClientId,
									mandatorySignIn: true,
								},
							},
						});
						cognitoUserPoolsTokenProvider.setKeyValueStorage(new CustomTokenStorage());
						this.$store.commit('setTenantSettings', tenant);
						this.tenantLoaded = true;
					}
				}).catch((error) => {
					if (lastApiTimestamp === this.lastApiTimestamp) {
						console.log(error);
						this.tenantInfo = {};
						this.tenantErrorMessages = ['Organization not found. Please check spelling.'];
					}
				});
		},
	},
};
</script>

<style lang="less">
.login-page {
	.sso-button {
		height: 100%;

		&.left-border {
			margin-left: 15px;
			border-left: 1px solid grey;
		}
	}
	.divider-flexbox {
		flex: 0 1 auto;
		padding: 0 10px;
	}

	.federated-signin-flexbox {
		flex: 0 1 auto;
	}

	.login-card {
		max-width: 600px;
		img {
			max-width: 100%;
		}
		.rich-text {
			color: black;

			*:empty {
				height: 1.1em;
			}

			p {
				margin: 0;
			}
			h1 {
				margin: 14px 0;
			}
			h2 {
				margin: 10px 0;
			}
			h3 {
				margin: 5px 0;
			}
			table {
				width: 100%;
				border-collapse: collapse;
				border: 1px solid grey;
				table-layout: fixed;
				th, td {
					border: 1px solid grey;
					padding: 8px;
					&.selectedCell {
						background-color: lightblue;
					}
				}
			}
		}
	}
}
</style>
